import { Card, CardDeck, Carousel, Col, Container, Row } from "react-bootstrap"
import { Link, withI18next } from "gatsby-plugin-i18next"

import { I18n } from "react-i18next"
import Img from "gatsby-image"
import Layout from "../components/layout"
import React from "react"
import RichTextRenderer from "../components/richTextRenderer"
import SEO from "../components/seo"
import { graphql } from "gatsby"

class McticHome extends React.Component {
  render() {
    const { data, lng } = this.props
    return (
      <I18n>
        {t => (
          <Layout pageContext={this.props.pageContext}>
            <SEO title={t("mctic")} />
            <div className="section bg-dark-blue mt-4">
              <Container className="height-md-30">
                <Row>
                  <Col md="8" lg="7" xl="6">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb p-0 bg-dark-blue bg-transparent">
                        <li className="breadcrumb-item">
                          <Link to="/" className="text-white">
                            {t("home")}
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/mctic-home" className="text-white">
                            {t("mctic")}
                          </Link>
                        </li>
                      </ol>
                    </nav>
                  </Col>
                  <Col md="12">
                    <h1 className="display-5 text-white text-center mctic-title">
                      {t("mctic_full")}
                    </h1>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="section pb-4 pt-4">
              <Container>
                <Row className="justify-content-center">
                  <Col lg="5">
                    <Carousel>
                      {data &&
                        data.images.nodes.map(image => (
                          <Carousel.Item key={image.name}>
                            <Img
                              className="d-block w-100"
                              fluid={image.childImageSharp.fluid}
                              alt={image.name}
                            />
                          </Carousel.Item>
                        ))}
                    </Carousel>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="section bg-dark-blue p-0">
              <Container>
                <Row className="no-gutters justify-content-center text-center">
                  <Col
                    lg="6"
                    className="border-right-lg d-lg-flex align-items-top"
                  >
                    <div className="p-5">
                      <span className="h2 d-block">{t("vision")}</span>
                      <p className="lead">{t("vision_text_mctic")}</p>
                    </div>
                  </Col>
                  <Col lg="6" className="d-lg-flex align-items-top">
                    <div className="p-5">
                      <span className="h2 d-block">{t("mission")}</span>
                      <p className="lead">{t("mission_text_mctic")}</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="section active-project m-0 pt-5 pb-3">
              <Container>
                <Row>
                  <Col>
                    <h2>{t("active_project")}</h2>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="section pt-4 pb-0 m-0 border-top">
              <Container>
                <Row>
                  <Col>
                    {data.active_projects.edges.map(project => (
                      <React.Fragment key={project.id}>
                        <h4>{project.node.title}</h4>
                        <h5>2016-2019</h5>
                        <p>{RichTextRenderer(project.node.body.json)}</p>
                      </React.Fragment>
                    ))}

                    <CardDeck>
                      <Card border="white">
                        <Img
                          fixed={data.technical_advisors.childImageSharp.fixed}
                          alt={t("mctic_home_stat_11")}
                          className="card-icon"
                        />
                        <Card.Body>
                          <div className="card-title h4">
                            {data.stats.stats.ta.national +
                              data.stats.stats.ta.canadian +
                              data.stats.stats.ta.long_term}
                          </div>
                          <Card.Text>{t("mctic_home_stat_1")}</Card.Text>
                          <Card.Text>
                            {t("mctic_home_stat_2")}
                            <span className="h5">
                              - {data.stats.stats.ta.national}
                            </span>
                          </Card.Text>
                          <Card.Text>
                            {t("mctic_home_stat_3")}
                            <span className="h5">
                              - {data.stats.stats.ta.canadian}
                            </span>
                          </Card.Text>
                          <Card.Text>
                            {t("mctic_home_stat_4")}
                            <span className="h5">
                              - {data.stats.stats.ta.long_term}
                            </span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <Card border="white">
                        <Img
                          fixed={data.training.childImageSharp.fixed}
                          alt={t("mctic_home_stat_11")}
                          className="card-icon"
                        />
                        <Card.Body>
                          <div className="card-title h4">
                            {data.stats.stats.training}
                          </div>
                          <Card.Text>{t("mctic_home_stat_5")}</Card.Text>
                        </Card.Body>
                      </Card>
                      <Card border="white">
                        <Img
                          fixed={data.book.childImageSharp.fixed}
                          alt={t("mctic_home_stat_11")}
                          className="card-icon"
                        />
                        <Card.Body>
                          <div className="card-title h4">
                            {data.stats.stats.handout}
                          </div>
                          <Card.Text>{t("mctic_home_stat_6")}</Card.Text>
                        </Card.Body>
                      </Card>
                      <div className="w-100 d-none d-sm-block d-lg-none"></div>
                      <Card border="white">
                        <Img
                          fixed={data.coop.childImageSharp.fixed}
                          alt={t("mctic_home_stat_11")}
                          className="card-icon"
                        />
                        <Card.Body>
                          <div className="card-title h4">
                            {data.stats.stats.unique.men +
                              data.stats.stats.unique.women}
                          </div>
                          <Card.Text>{t("mctic_home_stat_7")}</Card.Text>
                          <Card.Text>
                            {t("mctic_home_stat_8")}
                            <span className="h5">
                              - {data.stats.stats.unique.men}
                            </span>
                          </Card.Text>
                          <Card.Text>
                            {t("mctic_home_stat_9")}
                            <span className="h5">
                              - {data.stats.stats.unique.women}
                            </span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <Card border="white">
                        <Img
                          fixed={data.support.childImageSharp.fixed}
                          alt={t("mctic_home_stat_11")}
                          className="card-icon"
                        />
                        <Card.Body>
                          <div className="card-title h4">
                            {data.stats.stats.cooperative}
                          </div>
                          <Card.Text>{t("mctic_home_stat_10")}</Card.Text>
                        </Card.Body>
                      </Card>
                      <Card border="white">
                        <Img
                          fixed={data.plant.childImageSharp.fixed}
                          alt={t("mctic_home_stat_11")}
                          className="card-icon"
                        />
                        <Card.Body>
                          <div className="card-title h4">
                            {data.stats.stats.investment} {t("million")}
                          </div>
                          <Card.Text>{t("mctic_home_stat_11")}</Card.Text>
                        </Card.Body>
                      </Card>
                    </CardDeck>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="section recent-closed-project pt-5 pb-3">
              <Container>
                <Row>
                  <Col>
                    <h2>{t("recent_project")}</h2>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="section py-4 m-0 border-top">
              <Container>
                {data.last_project.edges.map(project => (
                  <Row key={project.id}>
                    <Col>
                      <h4>{project.node.title}</h4>
                      <h5>
                        {project.node.startDate}-{project.node.endDate}
                      </h5>
                      <p>{RichTextRenderer(project.node.body.json)}</p>
                    </Col>
                  </Row>
                ))}
              </Container>
            </div>
            <div className="section closed-project pt-5 pb-3">
              <Container>
                <Row>
                  <Col>
                    <h4>
                      {lng === "en" ? (
                        <a
                          href="https://drive.google.com/open?id=1UApM5wJ8lfud7tZ93ayG2WCCB_zkOmKzkpP6AkRXkBY"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("closed_project")}
                        </a>
                      ) : (
                        <a
                          href="https://docs.google.com/document/d/12Zblo-_l3w_ZmMkE_qCYPYduwS_6Dmi86nmSR_az1wI/edit?usp=sharing"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("closed_project")}
                        </a>
                      )}
                    </h4>
                  </Col>
                </Row>
              </Container>
            </div>
          </Layout>
        )}
      </I18n>
    )
  }
}

export default withI18next()(McticHome)

export const query = graphql`
  fragment fluidIcon on File {
    childImageSharp {
      fixed(width: 64, quality: 90) {
        ...GatsbyImageSharpFixed_noBase64
      }
    }
  }

  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
    plant: file(relativePath: { eq: "icons/plant.png" }) {
      ...fluidIcon
    }
    support: file(relativePath: { eq: "icons/support.png" }) {
      ...fluidIcon
    }
    book: file(relativePath: { eq: "icons/book.png" }) {
      ...fluidIcon
    }
    technical_advisors: file(
      relativePath: { eq: "icons/technical_advisors.png" }
    ) {
      ...fluidIcon
    }
    training: file(relativePath: { eq: "icons/training.png" }) {
      ...fluidIcon
    }
    coop: file(relativePath: { eq: "icons/cooperative.png" }) {
      ...fluidIcon
    }
    images: allFile(filter: { relativeDirectory: { eq: "mctic/home" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 445, maxHeight: 297, quality: 85) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    stats: contentfulStats(contentful_id: { eq: "7p9qGqYtbKSC2MUU9nbPkj" }) {
      id
      title
      stats {
        ta {
          national
          canadian
          long_term
        }
        training
        handout
        unique {
          men
          women
        }
        cooperative
        investment
      }
    }
    active_projects: allContentfulProject(
      filter: { node_locale: { eq: $lng }, status: { ne: 100 } }
      sort: { fields: startDate, order: DESC }
    ) {
      edges {
        node {
          id
          startDate
          endDate
          title
          body {
            json
          }
        }
      }
    }
    last_project: allContentfulProject(
      filter: { node_locale: { eq: $lng }, status: { eq: 100 } }
      sort: { fields: endDate, order: DESC }
    ) {
      edges {
        node {
          id
          startDate
          endDate
          title
          body {
            json
          }
        }
      }
    }
  }
`
